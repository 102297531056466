import { message } from "antd";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEventSignups } from "../reduxToolkit/slices/commonSlice";
import { db } from "../utils/firebaseConfig";

export interface EventSignup {
  timestamp: Timestamp;
  eventId: string;
  membershipId: string;
}
export function useEventSignup() {
  const [isBusy, setIsBusy] = useState(false);

  const membership = useSelector(
    (state: any) => state.common.currentMembership
  );

  const dispatch = useDispatch();

  async function loadAllEventSignupsForMembership(id: string){ 
    if(!id) return []; 
 
    const eventSignupRef = collection(db, "eventsignup");

    const q = query(eventSignupRef, where("membershipId", "==", id));
    const querySnapshot = await getDocs(q);

    let signups: any[] = [];

    querySnapshot.forEach((d) => {
      signups.push({ ...d.data(), id: d.id });
    });

    signups = signups.filter((s) => !s.cancelTimestamp);
    return signups; 
  } 

  async function loadAllMyEventSignups() {
    if (!membership) return;

    const signups = await loadAllEventSignupsForMembership(membership.id);

    dispatch(setEventSignups(signups));
  }

  async function loadAllSignupsForEvent(eventId: string) {
    if (!membership) return;

    const eventSignupRef = collection(db, "eventsignup");
    const q = query(eventSignupRef, where("eventId", "==", eventId));
    const querySnapshot = await getDocs(q);

    const signups: any[] = [];

    querySnapshot.forEach((d) => {
      signups.push({ ...d.data(), id: d.id });
    });

    const filteredSignups = signups.filter((s) => !s.cancelTimestamp);

    let signupsWithMembership: any[] = [];

    for await (const m of filteredSignups) {
      const docRef = doc(db, "communitymembership", m.membershipId);
      const docSnap = await getDoc(docRef);
      signupsWithMembership.push({ ...m, ...docSnap.data() });
    }

    return signupsWithMembership;
  }

  async function signupForEvent(eventId?: string) {
    if (!membership || !eventId) return;
    setIsBusy(true);
    const loader = message.loading("Signing up...", 0);
    try {
      const docRef = doc(db, "eventsignup", `${eventId}-${membership.id}`);
      await setDoc(docRef, {
        membershipId: membership.id,
        eventId,
        timestamp: serverTimestamp(),
        channel: "web",
      });
      await loadAllMyEventSignups();
      message.success("You have successfully signed up.");
    } catch (err) {
      console.error();
    }
    loader();
    setIsBusy(false);
  }

  async function cancelSignup(signupId?: string) {
    if (!signupId) return;
    setIsBusy(true);
    const loader = message.loading("Loading...", 0);
    try {
      const docRef = doc(db, "eventsignup", signupId);
      await updateDoc(docRef, {
        cancelTimestamp: serverTimestamp(),
      });
      await loadAllMyEventSignups();
      message.success("Signup cancelled.");
    } catch (err) {
      console.error(err);
    }
    loader();
    setIsBusy(false);
  }

  return {
    isBusy,
    loadAllMyEventSignups,
    signupForEvent,
    cancelSignup,
    loadAllSignupsForEvent, 
    loadAllEventSignupsForMembership
  };
}
