import { CalendarOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Tooltip, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCommunityNavigate } from "../../hooks/useCommunityNavigate";
import { EventSignup } from "../../hooks/useEventSignup";
import { RootState } from "../../reduxToolkit/store";

interface EventParticipationProps {
  signup?: EventSignup; 
  isSelf?:boolean; 
}

const { Title } = Typography;

export const EventParticipation: React.FC<EventParticipationProps> = (
  props
) => {
  const { signup, isSelf} = props; 
  const navigate = useCommunityNavigate(); 

  const events = useSelector((state: RootState) => state.common.events);
  if (!signup) return null;

  const match = events?.find((e) => e.id === signup.eventId);
  if (!match) return null; 

  return ( 
    <Card
    style={{ width: 300 }}
    cover={
      match?.headerImage && <img
        alt="example"
        src={match?.headerImage}
      />
    }
    actions={[
      <Tooltip><PlusOutlined onClick={() => navigate(`/event/${match.id}`)}/></Tooltip>,
      ... isSelf ? [<Tooltip><DeleteOutlined /></Tooltip>]: []
    ]}
  >
    <Meta
      avatar={<CalendarOutlined />}
      title={match?.name}
      description={match?.description}
    />
  </Card>
  )
};
