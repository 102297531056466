import { EventMessage } from "@tribe/types";
import { id } from "date-fns/locale";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useSelector } from "react-redux";
import { RootState } from "../reduxToolkit/store";
import { db } from "../utils/firebaseConfig";



export function useEventMessage(){ 
	const events = useSelector(
		(state: RootState) => state.common.events
	); 
	

	async function loadMessagesForEvent(eventId: string){  
		if(!eventId) return; 
		
		const eventRef = collection(db, "eventmessages");
		const q = query(eventRef, where("eventId", "==", eventId));
		const querySnapshot = await getDocs(q);

		const msgs: Partial<EventMessage>[] = [];
  
		querySnapshot.forEach((d) => {
		  const data = d.data();
  
		  msgs.push({
			id: d.id, 
			key: d.id,
			...data,
		  });
		});  
		console.log(msgs)
		return msgs.sort((a,b) => {
			return (a.timestampCreated?.toDate().getTime() ?? 1)- (b.timestampCreated?.toDate().getTime() ?? 1)
		 }) as EventMessage[];
	} 

	async function loadAlleEventMessagesForCommunity(){ 
		if(!events) return;  

		const allMessages : EventMessage[] = []; 

		await Promise.all(events.map(async (e) => {  
			const messages = await loadMessagesForEvent(e.id); 
			if(messages) allMessages.push(...messages); 
		}));   

		return allMessages;
	}  


	return {  
		loadMessagesForEvent, 
		loadAlleEventMessagesForCommunity
	}
}