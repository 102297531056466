import { Button, Space } from "antd";
import { Footer } from "antd/lib/layout/layout";
import { useSelector } from "react-redux";
import { useUser } from "../hooks/useUser";
import { RootState } from "../reduxToolkit/store";


export const FooterBar : React.FC = () => {  
	const { logout } = useUser();

	const user = useSelector((state: RootState) => state.common.user); 
	return <Footer style={{ textAlign: "center" }}><Space direction="horizontal">TeamTribe. Made with Love in Munich by ShowLab GmbH{user && <Button type="text" onClick={logout}>Logout</Button>}</Space></Footer>;
}