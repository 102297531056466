import { Card, Layout, Menu, Spin, Typography } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { BaseCenter } from "../../layouts/BaseCenter";
import { FooterBar } from "../../layouts/FooterBar";
import { RootState } from "../../reduxToolkit/store";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";

const { Title } = Typography;

export const Login: React.FC = () => {
  const [activeTab, setActiveTab] = useState("login");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const authProcessing = useSelector(
    (state: RootState) => state.common.authProcessing
  );
  const user = useSelector((state: RootState) => state.common.user);

  function handleRegistrationSuccess() {
    navigate("/");
  }

  useEffect(() => {
    if (user) {
      // Check if we have a redirect
      // Otherwise navigate
      const redirect = searchParams?.get("redirect");
      const active = searchParams?.get("active");
      if (redirect) {
        return navigate(`${redirect}${active ? `?active=${active}` : ""}`);
      }
      navigate("/");
    }
  }, [user]);

  const items: Record<string, React.ReactNode> = {
    login: <LoginForm />,
    register: <RegisterForm onRegisterSuccess={handleRegistrationSuccess} />,
  };

  if (authProcessing || user) return <Spin />;

  return (
    <BaseCenter>
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
          }}
        >
          <Logo />
          <Card
            tabList={[
              { key: "login", tab: "Login" },
              { key: "register", tab: "Register" },
            ]}
            activeTabKey={activeTab}
            onTabChange={(key) => setActiveTab(key)}
          >
            <div style={{ minWidth: "300px" }}>{items[activeTab]}</div>
          </Card>
        </div>
      </>
    </BaseCenter>
  );
};
