import { Button, Card, Form, Input, message, Typography } from "antd";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useCommunityData } from "../../hooks/useCommunityData";
import { RootState } from "../../reduxToolkit/store";
import { functions } from "../../utils/firebaseConfig";

const { Title } = Typography;

export const NoSlackOverlay: React.FC = () => {
  const membership = useSelector(
    (state: RootState) => state.common.currentMembership
  );

  const { loadAndSetCommunityMemberships } = useCommunityData();

  const [isLoading, setIsLoading] = useState(false);

  if (!membership) return null;

  async function onFinish(params: any) {
    console.log(params);
    if (!params.slackTeamId || !membership?.communityId) return;

    setIsLoading(true);

    try {
      const linkToSlack = httpsCallable(functions, "linkCommunityToSlack");
      const response: any = await linkToSlack({
        slackTeamId: params.slackTeamId,
        communityId: membership.communityId,
      });
      if (response?.data === "ok") {
        message.success("Community linked successfully");
        await loadAndSetCommunityMemberships();
      } else {
        throw new Error("invalid response");
      }
    } catch (err) {
      message.error("An error occured. Pleaes try again");
      setIsLoading(false);
    }
    // TODO
  }

  return (
    <Card>
      <div>
        <Title>{membership?.community?.name} is not linked to slack yet.</Title>
        {/* TODO: Exchange URL here */}
        <span
          dangerouslySetInnerHTML={{
            __html: `<a href='${process.env.REACT_APP_FUNCTIONS_BASE_URL}/integrations/slack/install'><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>`,
          }}
        />
      </div>
      <b>Install the Tribe Slack App and enter your teamcode below.</b>
      <Form
        name="basic"
        style={{ width: "50%" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="slackTeamId"
          rules={[
            {
              required: true,
              message: "Please input a slack teamcode!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Connect now
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
