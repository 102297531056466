import { Button, Popconfirm, Space, Tag, Tooltip } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SidebarContent } from "../../components/SidebarContent";
import { useEvent } from "../../hooks/useEvent";
import DashboardLayout from "../../layouts/DashboardLayout";
import { RootState } from "../../reduxToolkit/store";
import { CreateEventModal } from "./CreateEventModal"; 
import {EventInterface} from '@tribe/types';
import { render } from "@testing-library/react";
import { useCommunityNavigate } from "../../hooks/useCommunityNavigate";

export const Events: React.FC = () => {
  const navigate = useCommunityNavigate();

  const [isCreateEventVisible, setIsCreateEventVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const membership = useSelector(
    (state: RootState) => state.common.currentMembership
  );

  const events = useSelector((state: RootState) => state.common.events);

  const { loadAllEventsForCommunity, deleteEvent } = useEvent();

  async function fetchEvents() { 
    setIsLoading(true); 
    try {
      await loadAllEventsForCommunity();
    } catch (err) {
      console.error(err);
    } 
    setIsLoading(false); 
  }

  async function deleteSingleEvent(eventId: string) {
    setIsLoading(true);
    try {
      await deleteEvent(eventId);
      fetchEvents();
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchEvents();
  }, [membership]);

  const columns: ColumnsType<EventInterface> = [ 
    {
      title: "",
      dataIndex: "headerImage",
      key: "headerImage",
      render: (text: string, record) => {
        if(!text) return null; 
        return <img src={text} alt="headerimage" style={{height: '40px', width: '60px', objectFit: 'cover'}}/>;
      },
    }, 
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record) => (
        <a onClick={() => navigate(`/event/${record.id}`)}>{text}</a>
      ),
    }, 
    { 
      title: "Status", 
      dataIndex: "eventstatus", 
      key: "eventstatus", 
      render: (text) => { 
        if(text === 'internal') return <Tooltip title="Only visible for members of the community"><Tag color="blue">Internal</Tag></Tooltip>; 
        if(text === 'external') return <Tooltip title="Visible for everyone"><Tag color="green">Public</Tag></Tooltip>; 
        return <Tag>Draft</Tag>;
      } 
    },
    {
      title: "Start",
      dataIndex: "startDate",
      key: "startDate",
      render: (text: string, record) => text.toLocaleString(),
    },
    {
      title: "End",
      dataIndex: "endDate",
      key: "endDate",
      render: (text: string, record) => text.toLocaleString(),
    }, 
    { 
      title: "Signups",
      dataIndex: "signups",
      key: "signups",
      render: (text: string, record) => {  
        // TODO: Implement!
        return <>0</>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record) => (
        <Space>
          <Button onClick={() => navigate(`/event/${record.id}`)}>Edit</Button>
          <Popconfirm
            placement="top"
            title="Do you really want to delete this event?"
            onConfirm={() => deleteSingleEvent(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button>Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <CreateEventModal
        visible={isCreateEventVisible}
        onClose={() => {
          setIsCreateEventVisible(false);
          fetchEvents();
        }}
      />
      <DashboardLayout breadcrumbs={[{label: 'Events'}]}>
        <SidebarContent menuItems={[{ key: "overview", label: "Overview" }]}>
          <div style={{ float: "right" }}>
            <Button
              onClick={() => setIsCreateEventVisible(true)}
              type="primary"
              style={{ marginBottom: 16 }}
            >
              Create event
            </Button>
          </div>
          <Table columns={columns} dataSource={events?.map((e : any) => ({...e, key: e.id}))} loading={isLoading} />
        </SidebarContent>
      </DashboardLayout>
    </>
  );
};
