import { SendOutlined, CalendarOutlined, BulbOutlined, EditOutlined, CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { EventMessage } from "@tribe/types";
import { Tag, Space, Button, Tooltip, Popconfirm } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import { RootState } from "../../reduxToolkit/store";

interface CommunicationTableProps { 
	onDelete: (msgId :string) => void;  
	onSendMessageModal: (message: EventMessage) => void;   
	onScheduledMessageModal: (message: EventMessage) => void;  
	onUpdateModal: (message: EventMessage) => void;   
	onDuplicate: (message: EventMessage) => void; 
	messages: EventMessage[]; 
	isLoading: boolean; 
}

export const CommunicationTable : React.FC<CommunicationTableProps> = (props) => { 

	const {
		onDelete,  
		onSendMessageModal, 
		onScheduledMessageModal, 
		onUpdateModal,  
		onDuplicate, 
		messages, 
		isLoading
	} = props;  

	const membership = useSelector((state: RootState) => state.common.currentMembership); 

	const columns: ColumnsType<any> = [
		{
		  title: "Title",
		  dataIndex: "title",
		  key: "title", 
		  render: (text, record) => <>{text}{record.timestampScheduled && !record.timestampSent && <Tag>Scheduled</Tag>}</>
		},
		{
		  title: "Type",
		  dataIndex: "type",
		  key: "type",
		},
		{
		  title: "Senddate",
		  dataIndex: "timestampSent",
		  key: "timestampSent",
		  render: (text) => (!text ? "Draft" : text.toDate().toLocaleString()),
		},
		{
		  title: "Actions",
		  key: "actions",
		  render: (text, record) => (
			<Space>
			  {!record.timestampSent && (
				<>
				  <Button
					icon={<SendOutlined />} 
					disabled={record.timestampScheduled}
					onClick={() => onSendMessageModal(record)}
				  >
					Send
				  </Button>
				  <Button
					icon={<CalendarOutlined />}
					onClick={() => onScheduledMessageModal(record)}
				  >
					Schedule
				  </Button>
				  <Tooltip
					title={
					  !membership?.slackId
						? "Your account is not linked to a slack account."
						: "Send a testmessage via slack to yourself"
					}
				  >
					<Button 
					  icon={<BulbOutlined />} 
					  
					  onClick={() => onSendMessageModal(record)}
					  disabled={!membership?.slackId}
					>
					  Send Test
					</Button>
				  </Tooltip>
	  
			  
			  <Button
				icon={<EditOutlined />}
				onClick={() => onUpdateModal(record)}
			  >
				Edit
			  </Button> </>
			  )}
			   <Button
				icon={<CopyOutlined />}
				onClick={() => onDuplicate(record)}
			  >
				Duplicate
			  </Button>
			  <Popconfirm
				placement="top"
				title="Do you really want to delete this message?"
				onConfirm={() => onDelete(record.id)}
				okText="Yes"
				cancelText="No"
			  >
				<Button icon={<DeleteOutlined />}>Delete</Button>
			  </Popconfirm>
			</Space>
		  ),
		},
	  ]; 
	  
	return (
	 <Table columns={columns} dataSource={messages} loading={isLoading} />
	)
}