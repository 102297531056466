import { UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCommunityData } from "../../hooks/useCommunityData";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Member } from "../community/MemberList";
import { AvatarUpload } from "./AvatarUpload";
import { EventParticipationList } from "./EventParticipationList";

const { Title } = Typography;

export const SingleMember: React.FC = () => { 
  const { id } = useParams();

  const {getSingleMember} = useCommunityData();  
  const membership = useSelector((state : any) => state.common.currentMembership); 

  const [member, setMember] = useState<Member>();  

  const [isLoading, setIsLoading] = useState(false); 
  
  const isSelf = id && membership && id === membership.id; 

  async function fetch(){  
    if(!id) return; 

    setIsLoading(true);  
    try { 
      const m = await getSingleMember(id); 
      if(m) setMember(m as Member); 
    } catch(err){  
      console.error(err); 
    }
    setIsLoading(false); 
  }

  useEffect(() => {
    if(id) fetch(); 
  }, [id]); 

  useEffect(() => { 
    if(isSelf) fetch();
  }, [membership, member])
  
  return (
    <DashboardLayout> 
      <Row gutter={8}> 
        <Col span={6}>
          <Card> 
            <div style={{display: 'flex', flexDirection: 'column'}}>  
            <Space direction="vertical">  
              <Avatar src={member?.avatar} icon={<UserOutlined />} size="large" /> 
              {isSelf && <AvatarUpload />}
              <Title level={3}>{member?.displayName}{isSelf && <> (You)</>}</Title> 
            </Space>
            </div>
          </Card>
        </Col>
        <Col span={18}>
          <EventParticipationList isSelf={isSelf} />
        </Col>
      </Row>
    </DashboardLayout>
  );
};
