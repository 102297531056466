import {
  CheckCircleOutlined,
  LockOutlined,
  PlusOutlined
} from "@ant-design/icons";
import {
  Button,
  Col,
  FormInstance,
  Popconfirm,
  Row,
  Tooltip,
  Typography,
  Upload
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SidebarContent } from "../../components/SidebarContent";
import { useEvent } from "../../hooks/useEvent";
import { useEventSignup } from "../../hooks/useEventSignup";
import { useFileUpload } from "../../hooks/useFileUpload";
import DashboardLayout from "../../layouts/DashboardLayout";
import { EventCommunication } from "./EventCommunication";
import { EventDashboard } from "./EventDashboard";
import { EventGuestlist } from "./EventGuestlist";

const { Title, Paragraph } = Typography;

export const Event: React.FC = () => {
  const { id } = useParams();

  const { signupForEvent, cancelSignup, isBusy } = useEventSignup();
  const { updateEvent } = useEvent();

  const { uploadToStorage } = useFileUpload();

  const formRef = React.createRef<FormInstance>();

  const event = useSelector(
    (state: any) =>
      state.common.events?.find((e: any) => {
        console.log(e, id);
        return e.id === id;
      }),
    shallowEqual
  );

  const signup = useSelector(
    (state: any) =>
      state.common.eventSignups?.find((e: any) => {
        return e.eventId === id;
      }),
    shallowEqual
  );

  const eventMenuItems = [
    {
      key: "overview",
      label: "Overview",
      component: <EventDashboard event={event} />,
    },
    {
      key: "guestlist",
      label: (
        <>
          Guestlist{" "}
          <Tooltip title="The guestlist is currently only visible for you and other community admins">
            <LockOutlined />
          </Tooltip>
        </>
      ),
      component: <EventGuestlist />,
    },
    {
      key: "communication",
      label: "Communication",
      component: <EventCommunication />,
    },
  ];

  // TODO: What if the event is not found?

  useEffect(() => {
    console.log("Event menu items Change", eventMenuItems);
  }, [eventMenuItems]);

  function customUpload({ onError, onSuccess, file }: any) {
    uploadToStorage(file as Blob, `event-header-${new Date().getTime()}-${id}`)
      .then(async (downloadUrl) => {
        console.log(downloadUrl);
        await updateEvent(id, { headerImage: downloadUrl });
        onSuccess?.(downloadUrl);
      })
      .catch((err) => onError?.(err));
  }

  return (
    <>
      <DashboardLayout
        breadcrumbs={[
          { label: "Events", href: "/events" },
          { label: event?.name },
        ]}
      >
        <Row
          gutter={0}
          style={{
            width: "100%",
            backgroundColor: "white",
            padding: "24px",
            paddingTop: "150px",
            backgroundImage: event?.headerImage
              ? `url(${event?.headerImage})`
              : undefined,
          }}
        >
          <Col
            className="gutter-row"
            span={20}
            style={{
              backgroundColor: "white",
              opacity: "0.8",
              padding: "10px",
            }}
          >
            <Title level={2}>{event?.name}</Title>{" "}
            <ImgCrop aspect={1800 / 250}>
              <Upload
                maxCount={1}
                accept="image/*"
                showUploadList={false}
                customRequest={customUpload}
              >
                <div style={{ cursor: "pointer" }}>
                  <PlusOutlined /> Upload Header image
                </div>
              </Upload>
            </ImgCrop>
          </Col>
          <Col className="gutter-row" span={4}>
            {/* TODO: Vertically align to end */}
            <div style={{ alignSelf: "end" }}>
              {!signup ? (
                <Button
                  loading={isBusy}
                  style={{ float: "right" }}
                  type="primary"
                  icon={<CheckCircleOutlined />}
                  onClick={() => signupForEvent(id)}
                >
                  Signup for Event
                </Button>
              ) : (
                <Popconfirm
                  title="Do you really want to cancel your registration?"
                  onConfirm={() => cancelSignup(signup.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    loading={isBusy}
                    style={{
                      float: "right",
                      backgroundColor: "green",
                      borderColor: "green",
                    }}
                    type="primary"
                    icon={<CheckCircleOutlined />}
                  >
                    You are signed up
                  </Button>
                </Popconfirm>
              )}
            </div>
          </Col>
        </Row>
        <SidebarContent menuItems={eventMenuItems}></SidebarContent>
      </DashboardLayout>
    </>
  );
};
