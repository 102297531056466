import { Layout, Menu } from "antd"
import { Content } from "antd/lib/layout/layout"
import Sider from "antd/lib/layout/Sider"
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface SidebarContentProps { 
	children?: React.ReactNode; 
	menuItems?: {key: string, label: string | React.ReactNode, component? : React.ReactNode;}[];  
}

export const SidebarContent : React.FC<SidebarContentProps> = (props) => { 
	const {children, menuItems} = props;    

	const [searchParams] = useSearchParams();
	
	const [currentItem, setCurrentItem] = useState<string>();   

	useEffect(() => { 
		const active = searchParams?.get("active"); 

		if(menuItems && menuItems.length > 0){  
			/**
			 * If we are on a submenu and something changes we do not switch routes to first menu item. 
			 */
			const match = currentItem && !!menuItems.find((m) => m.key === currentItem);  
			if(match) return;
			const activeItemKey = menuItems.find((m) => m.key === active)?.key; 
			setCurrentItem(activeItemKey ?? menuItems[0].key);  
		}
	}, [menuItems]); 
	
	return ( <Layout className="site-layout-background" style={{ padding: "24px 0", flexDirection: 'row', minHeight: 300}}>
	<Sider className="site-layout-background" width={200} style={{ height: "100%" }}>
	  <Menu
		mode="inline"
		selectedKeys={currentItem ? [currentItem] : []}
		items={menuItems} 
		onClick={(item) => setCurrentItem(item.key)}
	  />
	</Sider>
	<Content style={{ padding: "0 24px" }}> 
		{menuItems?.find((item) => item.key === currentItem)?.component}
		{children}
	</Content>
  </Layout>)
}