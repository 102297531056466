import { Button, Card, Empty, Timeline } from "antd";
import { isAfter } from 'date-fns';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCommunityNavigate } from "../../hooks/useCommunityNavigate";
import { RootState } from "../../reduxToolkit/store";

export const EventOverviewCard: React.FC = () => {
  const events = useSelector((state: RootState) => state.common.events);
  
  const navigate = useCommunityNavigate();

  const upcomingEvents = events
    ?.filter((e) => isAfter(e.startDate, new Date()))
    .sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

  return (
    <Card
      title="Upcoming events" 
      loading={events === null}
      extra={<a onClick={() => navigate("/events")}>Create Event</a>}
    >
      <Timeline mode={"right"}>
        {upcomingEvents?.map((event) => (
          <Timeline.Item label={event.startDate.toLocaleString()}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/event/${event.id}`)}
            >
              {event.name}
            </div>
          </Timeline.Item>
        ))} 
      </Timeline> 
      { 
          upcomingEvents?.length === 0 &&  <Empty

          description={
            <span>
              No upcoming events
            </span>
          }
        >
          <Button type="primary" onClick={() => navigate('/events')}>Create Now</Button>
        </Empty>
        }
    </Card>
  );
};
