import "antd/dist/antd.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import { Community } from "./pages/community";
import { Events } from "./pages/events";
import { Event } from "./pages/event";
import { Home } from "./pages/home";
import { Login } from "./pages/login";
import { setCurrentMembership, setUser } from "./reduxToolkit/slices/commonSlice";
import { auth } from "./utils/firebaseConfig";
import { Account } from "./pages/account";
import { SingleMember } from "./pages/member";
import { useCommunityData } from "./hooks/useCommunityData";
import { useDataLoader } from "./hooks/useDataLoader";
import { RootState } from "./reduxToolkit/store";
import { UserHome } from "./pages/userhome/Userhome";
import { NotFound } from "./pages/NotFound";

function App() {
  const dispatch = useDispatch();
  const { loadAndSetCommunityMemberships} = useCommunityData();
  const { initialLoad } = useDataLoader(); 

  const user = useSelector((state: RootState) => state.common.user);
  const membership = useSelector(
    (state: RootState) => state.common.currentMembership
  );
  const memberships = useSelector(
    (state: RootState) => state.common.memberships
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      dispatch(setUser(user));
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if(user) loadAndSetCommunityMemberships();
  }, [user]); 

  useEffect(() => { 
    if (membership) initialLoad();
  }, [membership]); 

  useEffect(() => {
    if(membership){ 
      const match = memberships?.find((m) => m.communityId === membership.communityId); 
      if(match) dispatch(setCurrentMembership(match));
    }
  }, [memberships, membership])

  return (
    <Router>
      <Routes> 
        <Route  path="/login" element={<Login />} />
        <Route  path="/:communitySlug/account" element={<Account />} />
        <Route  path="/:communitySlug/account/:route" element={<Account />} />  
        <Route path="/:communitySlug" element={<Home />} />
        <Route path="/:communitySlug/community" element={<Community />} />
        <Route path="/:communitySlug/events" element={<Events />} />
        <Route path="/:communitySlug/event/:id" element={<Event />} />
        <Route path="/:communitySlug/member/:id" element={<SingleMember />} />
        <Route path="/" element={<UserHome />} /> 
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
