import { Empty } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCommunityNavigate } from "../../hooks/useCommunityNavigate";
import { EventSignup, useEventSignup } from "../../hooks/useEventSignup";

export const EventGuestlist: React.FC = () => {
  const [guests, setGuests] = useState<EventSignup[]>();
  const [fetchingGuests, setFetchingGuests] = useState(false);

  const { id } = useParams();

  const { loadAllSignupsForEvent } = useEventSignup();
  const navigate = useCommunityNavigate();

  async function fetch() {
    if (!id) return;
    setFetchingGuests(true);
    try {
      const signups = await loadAllSignupsForEvent(id);
      setGuests(signups);
    } catch (err) {
      console.error(err);
    }
    setFetchingGuests(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  const columns: ColumnsType<EventSignup> = [
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: "Signup date",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (text) => text.toDate().toLocaleString(),
    },
  ];
  return (
    <Table
      loading={fetchingGuests}
      columns={columns}
      dataSource={guests}  
      locale={{ emptyText:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
        <span>
          No guests have signed up for the event yet.
        </span>
      } />}}
      rowClassName="clickableRow"
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => navigate(`/member/${record.membershipId}`), // click row
        };
      }}
    />
  );
};
