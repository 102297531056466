import { Button, DatePicker, Form, FormInstance, Input, Modal } from "antd";
import { addDoc, collection, Timestamp} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../utils/firebaseConfig";
import * as moment from 'moment'; 

interface CreateEventModalProps {
  visible: boolean;
  onClose: () => void;
}

export const CreateEventModal: React.FC<CreateEventModalProps> = (props) => {
  const { visible, onClose } = props; 

  const membership = useSelector((state : any) => state.common.currentMembership )
  const formRef = React.createRef<FormInstance>();   

  const [isSubmitting, setIsSubmitting] = useState(false); 

  useEffect(() => {
    formRef?.current?.resetFields();
  }, [visible])

  async function handleSubmit(values: any){   
    const {name, startDate, endDate} = values; 
    if(!membership) return;   
    if(!membership.communityId) return; 

    if(values.startDate.isAfter(values.endDate)){ 
      return formRef.current?.setFields([{ 
        name: 'startDate', 
        errors: ['The startdate has to be before the enddate']
      }])
    };  

    setIsSubmitting(true);   
    try { 
      await addDoc(collection(db, "event"), {
        name, 
        startDate: Timestamp.fromDate(startDate.toDate()),  
        endDate: Timestamp.fromDate(endDate.toDate()), 
        communityId: membership.communityId
      }); 
      onClose();
    } catch(err){ 
      // TODO: Handle errors properly 
      console.error(err); 
    }
    setIsSubmitting(false); 

  }

  return (
    <Modal
      title="Create new Event"
      visible={visible}  
      onCancel={onClose}
      footer={[ 
        <Button onClick={() =>  formRef?.current?.submit()} loading={isSubmitting}>Submit</Button>, 
        <Button onClick={onClose} loading={isSubmitting}>Close</Button>, 
      ]}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }} 
        ref={formRef}
        onFinish={handleSubmit}
        layout="horizontal"
      >
        <Form.Item name="name" label="Name" rules={[{required: true, message: 'Please give this event a name'}]}>
          <Input disabled={isSubmitting} />
        </Form.Item>
        <Form.Item name="startDate" label="Startdate" rules={[{required: true}]}>
          <DatePicker  showTime={{
        format: "HH:mm"
      }} disabled={isSubmitting} format="DD.MM.YYYY HH:mm"  />
        </Form.Item>
        <Form.Item name="endDate" label="Enddate" rules={[{required: true}]}>
        <DatePicker  showTime={{
        format: "HH:mm"
      }} disabled={isSubmitting} format="DD.MM.YYYY HH:mm"  />
        </Form.Item>
      </Form>
    </Modal>
  );
};
