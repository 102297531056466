import { EventInterface } from "@tribe/types";
import { collection, query, where, getDocs, deleteDoc, doc, Timestamp, updateDoc} from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { setEvents } from "../reduxToolkit/slices/commonSlice";
import { db } from "../utils/firebaseConfig"; 
import * as _ from 'lodash'; 


export interface Event {
	id: string;
	name: string;
	key: string;
	startDate: Date;
	endDate: Date;
} 

export function useEvent(){ 

	const membership = useSelector(
		(state: any) => state.common.currentMembership
	);   

	const dispatch = useDispatch(); 

	async function loadAllEventsForCommunity(){   
	
		if (!membership) return; 

		const eventRef = collection(db, "event");
		const q = query(
		  eventRef,
		  where("communityId", "==", membership.communityId)
		);
		const querySnapshot = await getDocs(q);
  
		// TODO: Type
		const events: any[] = [];
  
		querySnapshot.forEach((d) => {
		  const data = d.data();
  
		  events.push({ 
			id: d.id,
			...data,
			startDate: data.startDate.toDate(),
			endDate: data.endDate.toDate(),
		  });
		}); 
		dispatch(setEvents(events)); 
	} 

	async function deleteEvent(eventId: string){  
		return deleteDoc(doc(db, "event", eventId));
	}  
 
	// Type adjustment - eventdate is moment here 
	async function updateEvent(id?: string, data : any= {}){  
		if(!id) return;
		const eventRef = doc(db, "event", id); 
		const payload = {
		  ...data,
		  ...data?.startDate ? {startDate: Timestamp.fromDate(
			data?.startDate?.toDate()
		  )} : {},
		  ...data?.endDate ? {endDate: Timestamp.fromDate(
			data?.endDate?.toDate()
		  )} : {},
		}

		console.log(payload);
		

		await updateDoc(eventRef, _.pickBy(payload, _.identity));
  
		loadAllEventsForCommunity();
	}

	return { 
		loadAllEventsForCommunity, 
		deleteEvent, 
		updateEvent
	}
}