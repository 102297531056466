import { deleteDoc, doc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useState } from "react";
import { CommunicationTable } from "../../components/Communication/CommunicationTable";
import { showErrorToast } from "../../utils/errorToast";
import { db } from "../../utils/firebaseConfig";


export const Communication : React.FC = () => { 

	const [messages, setMessages] = useState<any>(); 
	const [isLoading, setIsLoading] = useState(false); 
	const [sendModalMessage, setSendModalMessage] = useState<any>();
  	const [scheduleModalMessage, setScheduleModalMessage] = useState<any>(); 
	const [updateommunicationModalOpen, setUpdateCommunicationModalOpen] =
	  useState<any>(false); 

	async function fetch(){ 

	}

	async function handleDelete(msgId: string) { 
		console.log("Delete", msgId);
		setIsLoading(true);
		try {
		  await deleteDoc(doc(db, "eventmessages", msgId));
		  fetch();
		} catch (err) {
		  showErrorToast();
		}
		setIsLoading(false);
	  } 
	  async function handleDuplicate(msg: any){    
		const {key, id, ...m} = msg; 
		await addDoc(collection(db, "eventmessages"), {
		  ...m, 
		  timestampSent: null, 
		  timestampCreated: serverTimestamp()
		}); 
		fetch();
	  } 

	return (
		<> 
		 <CommunicationTable onDelete={handleDelete} onSendMessageModal={setSendModalMessage} onScheduledMessageModal={setScheduleModalMessage} onUpdateModal={setUpdateCommunicationModalOpen} onDuplicate={handleDuplicate} messages={messages} isLoading={isLoading} />
		</>
	)
}