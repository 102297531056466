import { Layout, Menu } from "antd";
import { Header, Content } from "antd/lib/layout/layout";
import React from "react";
import { FooterBar } from "./FooterBar";


export const BaseCenter : React.FC<{children: React.ReactNode}> = (props) => { 

	return  (<Layout style={{minHeight: '100vh'}}>
	<Header className="header">
  
	  <Menu
		theme="dark"
		mode="horizontal"
		defaultSelectedKeys={["2"]}
		items={[]}
	  />
	</Header>
	<Layout>
	  <Content
		className="site-layout-background"
		style={{
		  padding: 24,
		  margin: 0,
		  minHeight: 280,
		}}
	  >{props.children}</Content>
	  </Layout>  
	  <FooterBar />
	  </Layout>);
}