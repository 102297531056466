import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Input, Modal, Switch, Upload } from "antd";
import { addDoc, collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EventMessage } from "@tribe/types";
import { useFileUpload } from "../../../hooks/useFileUpload";
import { RootState } from "../../../reduxToolkit/store";
import { db } from "../../../utils/firebaseConfig";

interface NewCommunicationModalProps {
  visible: boolean; 
  updateElement?: EventMessage; 
  onClose: () => void;
}

const { TextArea } = Input;

export const NewCommunicationModal: React.FC<NewCommunicationModalProps> = (
  props
) => {
  const { visible, onClose, updateElement } = props;
  const { id } = useParams();
  const { uploadToStorage } = useFileUpload();

  const membership = useSelector(
    (state: RootState) => state.common.currentMembership
  );
  const event = useSelector(
    (state: RootState) =>
      state.common.events?.find((e: any) => {
        return e.id === id;
      }),
    shallowEqual
  );

  const formRef = React.createRef<FormInstance>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    formRef?.current?.resetFields(); 
    
    if(!updateElement) formRef?.current?.setFieldValue("title", `Signup for ${event?.name}`); 
    if(updateElement){ 
      formRef?.current?.setFieldsValue(updateElement); 
    }
  }, [event, visible, updateElement]);

  async function handleSubmit(values: any) {
    if (!membership) return;
    if (!membership.communityId) return;
    if (!event) return;
    if (!values) return;

    console.log(values);
    setIsSubmitting(true);
    try { 
      if(updateElement){  
        const msg = doc(db, "eventmessages", updateElement.id); 
        const {key, id, ...v} = values;  
        console.log(v);
		    await updateDoc(msg, {...v});   
      } else { 
      await addDoc(collection(db, "eventmessages"), {
        type: "slack",
        title: values.title,
        description: values.description ?? "",
        addSignupButton: values.addSignupButton ?? false,
        eventId: id,
        ...values.bannerImage ? {bannerImage: values.bannerImage}:{}, 
        timestampCreated: serverTimestamp()
      }); 
    }
      onClose();
    } catch (err) {
      // TODO: Handle errors properly
      console.error(err);
    }
    setIsSubmitting(false);
  }

  function customUpload({ onError, onSuccess, file }: any) {
    uploadToStorage(file as Blob, `event-header-${new Date().getTime()}-${id}`)
      .then(async (downloadUrl) => {
        onSuccess?.(downloadUrl);
      })
      .catch((err) => onError?.(err));
  }

  function getFile(e: any) {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return ''
    }
    return e?.file?.response;
  }

  return (
    <Modal
      title="Create new Message"
      visible={visible || !!updateElement}
      onCancel={onClose}
      footer={[
        <Button
          onClick={() => formRef?.current?.submit()}
          loading={isSubmitting}
        >
          Save
        </Button>,
        <Button onClick={onClose} loading={isSubmitting}>
          Abort
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 12 }}
        ref={formRef}
        onFinish={handleSubmit}
        layout="horizontal"
      >
        <Form.Item
          label="Banner Image"
          name="bannerImage"
          getValueFromEvent={getFile}
        >
          <Upload
            maxCount={1}
            accept="image/*"
            customRequest={customUpload}
            listType="picture-card"  
            defaultFileList={updateElement && updateElement.bannerImage? [{uid: '-1', name: 'image', status: 'done', url: updateElement.bannerImage}] : []}
          >
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item name="title" label="Title" required>
          <Input disabled={isSubmitting} />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item
          name="addSignupButton"
          label="Add signup button"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
