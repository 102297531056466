import { Spin } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../reduxToolkit/store";



export const AuthWrapper : React.FC<{children: React.ReactNode}> = (props) => { 

	const user = useSelector((state : RootState) => state.common.user);  
	const authProcessing = useSelector((state: RootState) => state.common.authProcessing);

	const navigate = useNavigate();

	const location = useLocation(); 
	
	useEffect(() => {
		if (!user && !authProcessing) {
		  navigate(`/login?redirect=${location?.pathname}`);
		}
	  }, [user, authProcessing]); 

	if(!user) return <Spin /> 
	
	return (<>{props.children}</>)
}