import { collection, getDocs, query, where, doc, getDoc, setDoc, addDoc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setCurrentMembership, setMemberships } from "../reduxToolkit/slices/commonSlice";
import { RootState } from "../reduxToolkit/store";
import { db } from "../utils/firebaseConfig";

export interface Community { 
	name: string;  
	slug: string;  
	slackTeamId: string; 
} 

export interface CommunityMembership { 
	role: 'member' | 'admin' | 'owner'; 
	community: Community; 
	communityId: string; 
	userId: string;   
	avatar?:string; 
	displayName?:string; 
	slackId?:string;
}

export function useCommunityData(){ 

	const user = useSelector((state : RootState) => state.common.user);  
	const currentMembership = useSelector((state : RootState) => state.common.currentMembership);  
	const memberships = useSelector((state : RootState) => state.common.memberships);  

	const { communitySlug } = useParams();   

	const dispatch = useDispatch(); 

	async function loadAllCommunityMemberships() : Promise<CommunityMembership[]> {  
		if(!user) return []; 

		const membershipRef = collection(db, "communitymembership");

		const q = query(membershipRef, where("userId", "==", user.uid)); 
		const querySnapshot = await getDocs(q); 
	
		const memberships : any[]= [];  

		querySnapshot.forEach((d) => { 
			memberships.push({...d.data(), id: d.id}); 
		});     

		const communityMemberships : any[] = []; 

		for await(const m of memberships){ 
			const docRef = doc(db, "community", m.communityId); 
			const docSnap = await getDoc(docRef);    
			communityMemberships.push({...m, community: docSnap.data()}); 
		}

		return communityMemberships; 
	} 

	async function loadAndSetCommunityMemberships(){ 
		const memberships = await loadAllCommunityMemberships(); 
		console.log("Load and set memberships", memberships); 
		dispatch(setMemberships(memberships)); 
	} 

	async function loadCurrentCommunityAndRole(){  
		if(!user) return;  
		if(!communitySlug) return; 

		if(communitySlug !== currentMembership?.community?.slug){ 
			dispatch(setCurrentMembership(null)); 
		} 

		const match = memberships?.find((m) => m.community?.slug === communitySlug); 
		if(match){ 
			dispatch(setCurrentMembership(match));
		}
	}    

	async function uniqueCommunitySlug(name: string){  
		const slug = name.toLowerCase().trim().replace(" ", "-"); 

		const communityRef = collection(db, "community");
		const q = query(communityRef, where("slug", "==", slug)); 
		const querySnapshot = await getDocs(q);  
		if(querySnapshot.docs?.length > 0) return `${slug}-${Date.now()}`; 

		return slug; 
	}


	async function createCommunity(name: string){  
		if(!user) return; 

		// TODO: Try / Catch 
		const slug =  await uniqueCommunitySlug(name); 
		const community = await addDoc(collection(db, "community"), {
			name,
			slug
		}); 

		await addDoc(collection(db, "communitymembership"), {
			userId: user.uid, 
			communityId: community.id, 
			role: 'owner', 
			displayName: user.email
		});  

		await loadAndSetCommunityMemberships(); 	
	} 

	async function getAllMembers(){   
		if(!currentMembership) return; 

		const membershipRef = collection(db, "communitymembership");

		const q = query(membershipRef, where("communityId", "==", currentMembership.communityId)); 
		const querySnapshot = await getDocs(q); 
	
		const members : any[]= [];  

		querySnapshot.forEach((d) => { 
			members.push({...d.data(), id: d.id}); 
		});       

		// TODO: Query auth for name 
		return members; 
	}  

	async function getSingleMember(memberId: string){ 
		if(!memberId) return; 

		const docRef = doc(db, "communitymembership", memberId);
		const docSnap = await getDoc(docRef); 
		return docSnap.data(); 
	}
	
	async function updateCommunity(data: any){  
		if(!user) return;   
		if(!currentMembership) return;  
		if(currentMembership.role !== 'owner' && currentMembership.role !== 'admin'){ 
			console.log("Missing privilege");
			return; 
		}
		try { 
		// TODO: Try / Catch  
		// TODO: Slack should be added via function  
		const docRef = doc(db, "community", currentMembership.communityId);

		await updateDoc(docRef, data); 
		await loadCurrentCommunityAndRole();  
		} catch(err){ 
			console.error(err); 
		}
	} 

	return { 
		loadCurrentCommunityAndRole,   
		uniqueCommunitySlug,
		loadAndSetCommunityMemberships,
		createCommunity, 
		getAllMembers, 
		updateCommunity, 
		getSingleMember
	}
}