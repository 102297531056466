import { UserOutlined } from "@ant-design/icons";
import { Avatar, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCommunityData } from "../../hooks/useCommunityData";
import { useCommunityNavigate } from "../../hooks/useCommunityNavigate";

export interface Member {
  avatar?: string;
  displayName: string;
  id: string;
  key: string;
  signupCount?: number;
}

export const MemberList: React.FC = () => {
  const navigate = useCommunityNavigate();

  const { getAllMembers } = useCommunityData();
  const membership = useSelector(
    (state: any) => state.common.currentMembership
  );

  const [members, setMembers] = useState<Member[]>();
  const [isLoading, setIsLoading] = useState(false);

  async function fetch() {
    setIsLoading(true);
    try {
      setMembers((await getAllMembers()) ?? []);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetch();
  }, [membership]);

  const columns: ColumnsType<Member> = [
    {
      title: "",
      dataIndex: "avatar",
      key: "avatar",
      width: "10%",
      render: (value) => <Avatar src={value} icon={<UserOutlined />} />,
    },
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (value) => <Tag>{value}</Tag>,
    },
    {
      title: "Signups",
      dataIndex: "signupCount",
      key: "signupCount",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={members}
      loading={isLoading}
      rowClassName="clickableRow"
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => navigate(`/member/${record.id}`), // click row
        };
      }}
    />
  );
};
