import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Select,
  Typography
} from "antd";
import { doc, Timestamp, updateDoc } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useEvent } from "../../hooks/useEvent";
import { showErrorToast } from "../../utils/errorToast";
import { db } from "../../utils/firebaseConfig"; 
import * as _ from 'lodash';  
import { useParams } from "react-router-dom";

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

interface EventDashboardProps {
  event?: any; // TODO: Type this
}

export const EventDashboard: React.FC<EventDashboardProps> = ({
  event,
}) => {
  const {id} = useParams(); 
  
  const [isUpdating, setIsUpdating] = useState(false);
  const formRef = React.createRef<FormInstance>(); 
  const {loadAllEventsForCommunity, updateEvent} = useEvent();  

  async function handleUpdate(params: any) {
    setIsUpdating(true);
    try {
      updateEvent(id, params); 
      message.success("Success");
    } catch (err) {
      console.error(err);
      showErrorToast();
    }
    setIsUpdating(false);
  }

  useEffect(() => {
    if (event) {
      formRef?.current?.setFieldsValue({
        ...event,
        startDate: moment(event.startDate),
        endDate: moment(event.endDate),
      });
    }
  }, [event]);

  if (!event) return null;

  return (
    <div>
      <Form
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        ref={formRef}
        onFinish={handleUpdate}
      >
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="eventstatus" label="Status">
          <Select defaultValue="draft">
            <Select.Option value="draft">Draft</Select.Option>
            <Select.Option value="internal">Published Internal</Select.Option>
            <Select.Option value="external">Published External</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="startDate" label="Startdate">
          <DatePicker showTime disabled={isUpdating} />
        </Form.Item>
        <Form.Item name="endDate" label="Enddate">
          <DatePicker showTime disabled={isUpdating} />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea rows={4} />
        </Form.Item> 
        <Form.Item name="participantLimit" label="Participant limit">
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={isUpdating}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
