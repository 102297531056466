import { doc, updateDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { setMemberships } from "../reduxToolkit/slices/commonSlice";
import { auth, db } from "../utils/firebaseConfig";
import { useCommunityData } from "./useCommunityData";


export function useUser(){ 

	const dispatch = useDispatch();  
	const currentMembership= useSelector((state : any) => state.common.currentMembership);  

	const {loadCurrentCommunityAndRole} = useCommunityData(); 

	function logout(){  
		auth.signOut();  
		dispatch(setMemberships([])); 
	}  

 
	// TODO: Move to community?
	async function updateUser(data: any){   
		if(!currentMembership || !currentMembership.id) return; 
		// TODO: Secure fields 
		const membership = doc(db, "communitymembership", currentMembership.id);
		await updateDoc(membership, data);   
		loadCurrentCommunityAndRole(); 
	} 

	return { 
		logout, 
		updateUser
	}
}