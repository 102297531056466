import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Breadcrumb, Layout,
  Menu,
  Popover,
  Spin,
  Tag
} from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthWrapper } from "../components/AuthWrapper";
import { Logo } from "../components/Logo";
import { NoCommunityOverlay } from "../components/NoCommunityOverlay";
import { NoMembershipOrNotFound } from "../components/NoMembershipOrNotFound";
import { NoSlackOverlay } from "../components/NoSlackOverlay";
import { useCommunityData } from "../hooks/useCommunityData";
import { useCommunityNavigate } from "../hooks/useCommunityNavigate";
import { useUser } from "../hooks/useUser";
import { RootState } from "../reduxToolkit/store";
import { FooterBar } from "./FooterBar";

const { Header, Content, Sider } = Layout;

interface DashboardLayoutProps {
  children: React.ReactNode;
  breadcrumbs?: { label: string; href?: string }[];
}
const DashboardLayout: React.FC<DashboardLayoutProps> = (props) => {
  const { breadcrumbs = [] } = props;

  const user = useSelector((state: RootState) => state.common.user);
  
  const currentMembership = useSelector(
    (state: RootState) => state.common.currentMembership
  );
  const memberships = useSelector((state: RootState) => state.common.memberships);

  const navigate = useCommunityNavigate(); 
  const { loadCurrentCommunityAndRole} = useCommunityData(); 
  
  const { pathname } = useLocation(); 
  const { communitySlug} = useParams(); 

  const { logout } = useUser(); 

  useEffect(() => {  
    if(memberships && communitySlug && user) loadCurrentCommunityAndRole();  
  }, [communitySlug, memberships, user])


  const mainMenuItems = [
    {
      key: "/",
      label: "Home",
    },
    {
      key: "community",
      label: "Community",
    },
    {
      key: "events",
      label: "Events",
    },
  ];

  function renderContent(){   
    if (!memberships || !user) return <Spin />;  
    
    if(!currentMembership || (memberships && memberships.length === 0)) return <NoMembershipOrNotFound />;
    if(!currentMembership?.community?.slackTeamId) return <NoSlackOverlay />

    return props.children; 
  }

  return ( 
    <AuthWrapper> 
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="header" id="layout-menu">
        <div style={{float: 'left', marginRight: '20px'}}><Logo bright/></div>
        <div style={{ float: "right", color: "white" }}>
          <Popover
            placement="bottom"
            content={<a onClick={logout}>Logout</a>}
            trigger="hover"
          >
            <div>
              <Avatar src={currentMembership?.avatar} size="large" icon={<UserOutlined />} />{" "}
              {currentMembership?.displayName ?? user?.email}{" "}
              <Tag
                onClick={() => navigate("/account")}
                style={{ cursor: "pointer" }}
                color="green"
              >
                Free Plan
              </Tag>
            </div>
          </Popover>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[...mainMenuItems.filter((i) => pathname.replace(`/${currentMembership?.community?.slug}/`, '') === i.key.replace('/', '')).map((i) => i.key)]}
          items={currentMembership?.role ? mainMenuItems : []} 
          // TODO!: Replace community slug 
          onClick={(item) => item.key !== '/' ? navigate(item.key) : navigate('/')}
        />
      </Header>

      <Content style={{ padding: "0 50px", flex: 1 }}>
        <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item onClick={() => navigate(`/`)}>{currentMembership?.community?.name}</Breadcrumb.Item>
          {breadcrumbs?.map((item, index) => (
            <Breadcrumb.Item 
              key={`breadcrumb-${index}`}
              className={item.href ? 'pointer':undefined}
              onClick={(e) => {
                e.stopPropagation();
                if (item.href) navigate(item.href);
              }}
            >
              {item.label}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb> 
        {renderContent()}
      </Content>

      <FooterBar />
    </Layout> 
    </AuthWrapper>
  );
};

export default DashboardLayout;
