import { Spin } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthWrapper } from "../../components/AuthWrapper";
import { NoCommunityOverlay } from "../../components/NoCommunityOverlay";
import { useCommunityData } from "../../hooks/useCommunityData";
import { BaseCenter } from "../../layouts/BaseCenter";
import { setCurrentMembership } from "../../reduxToolkit/slices/commonSlice";
import { RootState } from "../../reduxToolkit/store";
import { showErrorToast } from "../../utils/errorToast";

export const UserHome: React.FC = () => {
  const membership = useSelector(
    (state: RootState) => state.common.currentMembership
  );
  const memberships = useSelector(
    (state: RootState) => state.common.memberships
  );
  
  const navigate = useNavigate(); 
  const dispatch = useDispatch();  
  const {uniqueCommunitySlug, updateCommunity, loadAndSetCommunityMemberships} = useCommunityData(); 

  useEffect(() => { 
	console.log(memberships);
    if (membership && membership?.community?.slug) {
      navigate(`/${membership.community.slug}`);
    }
  }, [membership]); 

  useEffect(() => {
	if(memberships && memberships?.length > 0 && !membership){ 
		dispatch(setCurrentMembership(memberships[0]));
	}
  }, [memberships, membership]); 

  async function generateCommunitySlug(){ 
	if(!membership) return;  
	try {
	const uniqueSlug = await uniqueCommunitySlug(membership?.community?.name); 
	console.log(uniqueSlug);
	await updateCommunity({slug: uniqueSlug});  
	loadAndSetCommunityMemberships();
	} catch(err){ 
		showErrorToast(); 
	}

  }
  function renderContent() {
	if(memberships === null) return <Spin />;
    if (!membership) return <NoCommunityOverlay />; 
	console.log(membership);
    if (membership && !membership?.community?.slug) generateCommunitySlug(); 
    return <Spin />;
  }

  return (
    <AuthWrapper>
      <BaseCenter>{renderContent()}</BaseCenter>
    </AuthWrapper>
  );
};
