import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../reduxToolkit/store";


export function useCommunityNavigate(){ 

	const n = useNavigate(); 
	const membership = useSelector((state: RootState) => state.common.currentMembership); 
	

	function navigate(path: string){   
		if(!membership || !membership.community?.slug) return; 

		return n(`/${membership.community?.slug}${path.startsWith('/') ? '' : '/'}${path}`);  
	} 
	return navigate; 
}