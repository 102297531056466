import { initializeApp } from "firebase/app"; 
import { getAuth } from "firebase/auth";   
import { getStorage} from "firebase/storage";
import { getFirestore, initializeFirestore } from "firebase/firestore"; 
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";



const firebaseConfigProd = {
	apiKey: "AIzaSyDLbGDqjPeqg5MaV9476xMOO6IQj2L42nw",
	authDomain: "project-frank-4b4a0.firebaseapp.com",
	projectId: "project-frank-4b4a0",
	storageBucket: "gs://project-frank-4b4a0.appspot.com",
	messagingSenderId: "653426386099",
	appId: "1:653426386099:web:1ecf064be08e8a0657bcc6",
	measurementId: "G-4Z6F9Y0T74",
  }; 

  const firebaseConfigLocal = {
	apiKey: "AIzaSyDstd4HEiAqwplQRX80ssZoXvLPdyfJ4Ww",
	authDomain: "teamtribe-dev.firebaseapp.com",
	projectId: "teamtribe-dev",
	storageBucket: "teamtribe-dev.appspot.com",
	messagingSenderId: "517390735104",
	appId: "1:517390735104:web:55c0286418e8b39432c5e5",
	measurementId: "G-QQSZV981E2"
  };
  
  export const app = initializeApp(process.env.REACT_APP_FIREBASE_ENV === 'local' ? firebaseConfigLocal : firebaseConfigProd); 
  export const auth = getAuth(app); 
  export const db = initializeFirestore(app, {ignoreUndefinedProperties: true})
  
  export const storage = getStorage(app);

  export const functions = getFunctions(app);   
  if(process.env.REACT_APP_FIREBASE_ENV === 'local'){ 
	console.log("connecting to emulator");
	connectFunctionsEmulator(functions, "localhost", 5001);  
  }