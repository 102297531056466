import { SidebarContent } from "../../components/SidebarContent";
import DashboardLayout from "../../layouts/DashboardLayout"
import { Communication } from "./Communication";
import { MemberList } from "./MemberList";


export const Community : React.FC = () => {

	const eventMenuItems = [ 
		{ 
			key: 'memberlist', 
			label: 'Memberlist', 
			component: <MemberList />
		}, 
		{
			key: 'communication', 
			label: 'Communication', 
			component: <Communication />
		}
	] 
	
  return (
    <DashboardLayout breadcrumbs={[{label: 'Community'}]}>
		<SidebarContent menuItems={eventMenuItems}> 
		</SidebarContent>
    </DashboardLayout>
  );
} 