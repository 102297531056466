import { EventMessage } from "@tribe/types";
import { Card, Empty, Tag, Timeline } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEventMessage } from "../../hooks/useEventMessage";
import { RootState } from "../../reduxToolkit/store";

export const ScheduledMessages: React.FC = () => {
  const events = useSelector((state: RootState) => state.common.events);

  const [messages, setMessages] = useState<EventMessage[]>();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { loadAlleEventMessagesForCommunity } = useEventMessage();

  async function fetch() {
    setIsLoading(true);
    try {
      const msg = await loadAlleEventMessagesForCommunity();
      setMessages(msg);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (events) fetch();
  }, [events]);

  const onlyScheduledMessages = messages
    ?.filter((msg) => !msg.timestampSent && msg.timestampScheduled)
    ?.sort(
      (a, b) =>
        a.timestampScheduled.toDate().getTime() -
        b.timestampScheduled.toDate().getTime()
    );

  return (
    <Card title="Scheduled communications" loading={isLoading}>
      <Timeline mode={"right"}>
        {onlyScheduledMessages?.map((msg) => (
          <Timeline.Item
            label={msg.timestampScheduled.toDate().toLocaleString()}
          >
            {msg.title} <Tag color="green">Slack</Tag>
          </Timeline.Item>
        ))}
      </Timeline>
      {onlyScheduledMessages && onlyScheduledMessages?.length === 0 && (
        <Empty description={<span>Scheduled messages will appear here</span>} />
      )}
    </Card>
  );
};
