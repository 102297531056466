import { Button, Input } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useUser } from "../../hooks/useUser";
import { db } from "../../utils/firebaseConfig";



export const PersonalData : React.FC = () => { 

	const user = useSelector((state : any) => state.common.user);  
	const membership = useSelector((state : any) => state.common.currentMembership); 
	const {updateUser} = useUser();  

	const formRef = React.createRef<FormInstance>();   

	async function onFinish(values: any){   
		if(!membership.userId) return;
		console.log(values); 
		 await updateUser(values); 
	}  

	useEffect(() => {
		formRef?.current?.setFieldsValue({ 
			displayName: membership?.displayName
		})
	}, [membership])

	return (<Form
		name="basic" 
		ref={formRef}
		labelCol={{ span: 8 }}
		wrapperCol={{ span: 8 }}
		initialValues={{ remember: true }}
		onFinish={onFinish}
		autoComplete="off" 
		style={{float: 'left', width: '100%'}}
	  >
		<Form.Item
		  label="Your Displayname"
		  name="displayName"  
		  
		  rules={[{ required: true, message: 'Please input your username!' }]}
		>
		  <Input />
		</Form.Item>
  
		<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
		  <Button type="primary" htmlType="submit">
			Save
		  </Button>
		</Form.Item>
	  </Form>)
}