import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload, UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import { useFileUpload } from "../../hooks/useFileUpload";
import { useUser } from "../../hooks/useUser";

export const AvatarUpload: React.FC = () => {
  const { updateUser } = useUser();
  const { uploadToStorage } = useFileUpload();

  const props: UploadProps = {
    name: "file",
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false,
    accept: "image/*",
    maxCount: 1,
    customRequest: ({ onError, onSuccess, file }) => {
      const randomName = new Date().getTime();
      uploadToStorage(file as Blob, `avatar-${randomName}`)
        .then(async (downloadUrl) => {
          updateUser({ avatar: downloadUrl });

          onSuccess?.(downloadUrl);
        })
        .catch((err) => onError?.(err));
    },
    onChange(info) {
      console.log(info);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <ImgCrop shape="round">
      <Upload {...props}>
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </ImgCrop>
  );
};
