import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventInterface } from "@tribe/types";
import { User } from "firebase/auth";
import { CommunityMembership } from "../../hooks/useCommunityData";
import { EventSignup } from "../../hooks/useEventSignup";
export interface CommonState { 
	user: User | null;  
	authProcessing: boolean;  
	currentMembership: CommunityMembership | null; 
	memberships: CommunityMembership[] | null;  
	events: EventInterface[] | null;  
	eventSignups: EventSignup[] | null; 
} 

const initialState : CommonState = { 
	user: null, 
	authProcessing: true,  
	currentMembership: null, 
	memberships: null, 
	events: null, 
	eventSignups: null
} 

const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User | null>) => {
			state.user = action.payload; 
			state.authProcessing = false;
		},
		setCurrentMembership: (state, action: PayloadAction<CommunityMembership | null>) => { 
			state.currentMembership = action.payload;
		},  
		setMemberships:  (state, action: PayloadAction<CommunityMembership[] | null>) => { 
			state.memberships = action.payload;  
		},  
		setEvents:(state, action: PayloadAction<EventInterface[] | null>) => {
			state.events = action.payload;
		}, 
		setEventSignups:(state, action: PayloadAction<EventSignup[] | null>) => {
			state.eventSignups = action.payload;
		}, 
	}
}); 

export const { 
	setUser, 
	setMemberships, 
	setCurrentMembership,  
	setEvents, 
	setEventSignups
} = commonSlice.actions;

export default commonSlice.reducer;
