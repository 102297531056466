import { useEvent } from "./useEvent";
import { useEventSignup } from "./useEventSignup"


export function useDataLoader(){ 

	const {loadAllMyEventSignups} = useEventSignup(); 
	const {loadAllEventsForCommunity} = useEvent(); 
	
	async function initialLoad(){ 
		try { 
			await Promise.all([loadAllMyEventSignups(), loadAllEventsForCommunity()]);
		} catch(err){ 
			console.error(err); 
		}
	} 

	return {
		initialLoad
	}
}