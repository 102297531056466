import { Card, Empty, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventSignup, useEventSignup } from "../../hooks/useEventSignup";
import { EventParticipation } from "./EventParticipation";

const { Title } = Typography; 

interface EventParticipationListProps { 
  isSelf?:boolean; 
}

export const EventParticipationList: React.FC<EventParticipationListProps> = ({isSelf}) => {
  const { id } = useParams();

  const [signups, setSignups] = useState<EventSignup[]>();

  const { loadAllEventSignupsForMembership } = useEventSignup(); 

  // TODO: Issue here - signups can have deleted events - therefore empty is not shown 

  async function fetchEvents() {
    if (id) {
      const s = await loadAllEventSignupsForMembership(id);
      setSignups(s);
    }
  }
  useEffect(() => {
    fetchEvents();
  }, [id]); 

  return (
    <Card>
      <Title level={3}>Events</Title> 
      <Space direction="vertical"> 
      {signups?.map((s) => (
        <EventParticipation isSelf={isSelf} key={s.eventId} signup={s} />
      ))}  
       {signups && signups?.length === 0 && <Empty description={<span>No eventparticipations yet.</span>} />}
      </Space>
    </Card>
  );
};
