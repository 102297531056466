import { Button, Card, Form, Input, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useCommunityData } from "../../hooks/useCommunityData";

const { Title } = Typography;

export const NoCommunityOverlay: React.FC = () => {
  const { createCommunity } = useCommunityData();

  const navigate = useNavigate(); 

  const onFinish = async (values: any) => {
    const community : any = await createCommunity(values.name);  
    console.log(community); 
  };

  return (
    <Card>
      <Title>Welcome to TeamTribe</Title>
      <b>What is the name of your community?</b>
      <Form
        name="basic"
        style={{ width: "50%" }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please input a name for your community!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Start Free Plan
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
