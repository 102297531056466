import {
  Button,
  DatePicker,
  Empty,
  Form, FormInstance,
  message,
  Modal, Radio,
  Select
} from "antd";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { functions } from "../../../utils/firebaseConfig";

interface SendModalProps {
  guestMessage: any;
  onClose: () => void; 
  scheduleGuestMessage: any; 
}
const { Option } = Select;

export const SendModal: React.FC<SendModalProps> = (props) => {
  const { guestMessage, onClose, scheduleGuestMessage } = props;
  const { id } = useParams();
  const membership = useSelector(
    (state: any) => state.common.currentMembership
  );
  const formRef = React.createRef<FormInstance>(); 

  const internalMessage = scheduleGuestMessage || guestMessage;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [slackChannels, setSlackChannels] =
    useState<{ label: string; id: string }[]>();

  async function fetchSlackChannels() {
    if (!membership?.community?.slackTeamId || !id || !message) return;

    const loadSlackChannels = httpsCallable(functions, "syncChannels");
    const response: any = await loadSlackChannels({
      teamId: membership?.community?.slackTeamId,
    });
    setSlackChannels(
      response.data.map((d: any) => ({ label: d.name, id: d.id })) ?? []
    );
  }

  useEffect(() => {
    if(internalMessage) fetchSlackChannels();
  }, [internalMessage]);

  useEffect(() => {
    formRef?.current?.resetFields();  
    // TODO: Reset does not seem to be working properly
    if(internalMessage) formRef?.current?.setFieldsValue(internalMessage)
    setIsSubmitting(false);
  }, [internalMessage]);

  async function sendCommunication(record: any) {
    if (!membership?.community?.slackTeamId || !id) return;
    if (!internalMessage) return;
    setIsSubmitting(true); 
    const load = message.loading(`${guestMessage ? 'Sending' : 'Scheduling'} message`);
    try {
      const postEventToSlack = httpsCallable(functions, "postEventToSlack");
      // TODO: Send options not supported yet
      await postEventToSlack({
        eventMessageId: internalMessage.id,
        channelId: record.channelId,
        teamId: membership?.community?.slackTeamId,
        sendDate: record.sendDate ?record.sendDate.toDate() :undefined
      });
      message.success(`Message ${guestMessage ? 'sent' : 'scheduled'} successfully`); 
      onClose();
    } catch (err) {
      message.error("An error occured.");
    }
    load(); 
    setIsSubmitting(false);
  }

  return (
    <Modal
      title={`Send Message: ${internalMessage?.title}`}
      visible={!!internalMessage}
      width="50vw"
      onCancel={onClose}
      footer={[
        <Button
          onClick={() => formRef?.current?.submit()}
          loading={isSubmitting}
        >
          {!!guestMessage ? "Send": "Schedule"}
        </Button>,
        <Button onClick={onClose} loading={isSubmitting}>
          Close
        </Button>,
      ]}
    >
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        ref={formRef}
        onFinish={sendCommunication}
        layout="horizontal"
      >
        <Form.Item label="Slack Send Option" name="sendOption">
          <Radio.Group defaultValue="channel">
            <Radio.Button value="channel">Slack Channel</Radio.Button>
            <Radio.Button disabled value="dm">DM to registered users [coming soon]</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="channelId"
          label="Channel"
          rules={[{ required: true, message: "Please select a channel" }]}
        >
          <Select
            placeholder="Select a option and change input text above"
            allowClear
            notFoundContent={<span>No channel found! Make sure to add TeamTribe to a channel</span>}
            disabled={slackChannels === undefined}
            loading={slackChannels === undefined}
          >
            {slackChannels?.map((s) => (
              <Option value={s.id}>{s.label}</Option>
            ))}
          </Select>
        </Form.Item> 
        <Form.Item name="sendDate" label="Schedule Date"> 
            {/* TODO: Disable past */}
          <DatePicker showTime disabled={isSubmitting}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};
