import {
  BulbOutlined,
  CalendarOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MessageOutlined,
  SendOutlined
} from "@ant-design/icons";
import { Button, Popconfirm, Space, Tag, Tooltip } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import {
  addDoc,
  collection, deleteDoc,
  doc, getDocs, orderBy, query,
  serverTimestamp,
  where
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { showErrorToast } from "../../../utils/errorToast";
import { db } from "../../../utils/firebaseConfig";

import { NewCommunicationModal } from "./NewCommunicationModal";
import { SendModal } from "./SendModal"; 
import { EventMessage } from '@tribe/types';
import { useEventMessage } from "../../../hooks/useEventMessage";
import { CommunicationTable } from "../../../components/Communication/CommunicationTable";



export const EventCommunication: React.FC = () => { 
  const { id } = useParams();  
  const {loadMessagesForEvent} = useEventMessage(); 
  const [messages, setMessages] = useState<EventMessage[]>([]);


  const [isLoading, setIsLoading] = useState(false);
  const [newCommunicationModalOpen, setNewCommunicationModalOpen] =
    useState(false);
  const [updateommunicationModalOpen, setUpdateCommunicationModalOpen] =
    useState<any>(false);
  const [sendModalMessage, setSendModalMessage] = useState<any>();
  const [scheduleModalMessage, setScheduleModalMessage] = useState<any>();

  async function fetch() {
    if (!id) return;
    setIsLoading(true);
    try {
      const messages = await loadMessagesForEvent(id); 
      
      if(messages) setMessages(messages);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    fetch();
  }, [id]);

  async function handleDelete(msgId: string) { 
    console.log("Delete", msgId);
    setIsLoading(true);
    try {
      await deleteDoc(doc(db, "eventmessages", msgId));
      fetch();
    } catch (err) {
      showErrorToast();
    }
    setIsLoading(false);
  } 
  async function handleDuplicate(msg: any){    
    const {key, id, ...m} = msg; 
    await addDoc(collection(db, "eventmessages"), {
      ...m, 
      timestampSent: null, 
      timestampCreated: serverTimestamp()
    }); 
    fetch();
  }

  const membership = useSelector(
    (state: any) => state.common.currentMembership
  );


  return (
    <div>
      <NewCommunicationModal
        visible={newCommunicationModalOpen} 
        updateElement={updateommunicationModalOpen}
        onClose={() => {
          fetch(); 
          setUpdateCommunicationModalOpen(undefined);  
          setNewCommunicationModalOpen(false);
        }}
      />
      <SendModal
        guestMessage={sendModalMessage} 
        scheduleGuestMessage={scheduleModalMessage}
        onClose={() => {
          fetch();
          setSendModalMessage(undefined); 
          setScheduleModalMessage(undefined);
        }}
      />
      <Button
        icon={<MessageOutlined />}
        onClick={() => setNewCommunicationModalOpen(true)}
        type="primary"
        style={{ marginBottom: 16, float: "right" }}
      >
        Create new Message
      </Button> 
      <CommunicationTable onDelete={handleDelete} onSendMessageModal={setSendModalMessage} onScheduledMessageModal={setScheduleModalMessage} onUpdateModal={setUpdateCommunicationModalOpen} onDuplicate={handleDuplicate} messages={messages} isLoading={isLoading} />
    </div>
  );
};

