import { SidebarContent } from "../../components/SidebarContent";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Integrations } from "./Integrations";
import { PersonalData } from "./PersonalData";
import { TeamSetting } from "./TeamSetting";

export const Account: React.FC = () => {
  const accountMenuItems = [
    {
      key: "personal",
      label: "Personal",
      component: <PersonalData />,
    },
    /* {
      key: "team",
      label: "Team",
      component: <TeamSetting />,
    },
    {
      key: "integrations",
      label: "Integrations",
      component: <Integrations />,
    },
    {
      key: "subscriptions",
      label: "Subscription",
    },*/
  ];

  return (
    <>
      <DashboardLayout>
        <SidebarContent menuItems={accountMenuItems}></SidebarContent>
      </DashboardLayout>
    </>
  );
};
