import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../utils/firebaseConfig";



export function useFileUpload(){

	async function uploadToStorage(file: Blob, name = new Date().getTime().toString()){  
		const storageRef = ref(storage, name);  
		const snapshot = await uploadBytes(storageRef, file as Blob) 
		const download = await getDownloadURL(snapshot.ref)  
		return download;
	}

	return { 
		uploadToStorage
	}
}