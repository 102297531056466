import { Space, Alert, Form, Input, Button, Spin } from "antd";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setUser } from "../../reduxToolkit/slices/commonSlice";
import { auth } from "../../utils/firebaseConfig";


interface RegisterFormProps {
	onRegisterSuccess: () => void; 
 } 

export const RegisterForm : React.FC<RegisterFormProps> = (props) => {  
	const {onRegisterSuccess} = props; 

	const [searchParams, setSearchParams] = useSearchParams();

	const [registerFailed, setRegisterFailed] = useState(false); 
	const [isLoading, setIsLoading] = useState(false); 
  
	const onFinish = async (values: { email: string; password: string }) => { 
		setIsLoading(true); 
	  createUserWithEmailAndPassword(auth, values.email, values.password)
		.then((userCredential) => { 
			setIsLoading(false); 
			onRegisterSuccess();  
			
		})
		.catch((error) => { 
			setIsLoading(false); 
		  setRegisterFailed(true);
		  console.error(error);
		});
	};
  
	const onFinishFailed = (errorInfo: any) => {
	  console.log("Failed:", errorInfo);
	};
  
	if(isLoading) return <Spin />
	return (
	  <Space direction="vertical">
		{registerFailed && (
		  <Alert
			message="Registration Error"
			description="Registration was not successful. Please try again."
			type="error"
			closable
			onClose={() => setRegisterFailed(false)}
		  />
		)}  
		{(searchParams.get('beta') === 'true')? 
		<Form
		  name="basic"
		  labelCol={{ span: 10 }}
		  wrapperCol={{ span: 20 }}
		  initialValues={{ remember: true }}
		  onFinish={onFinish}
		  onFinishFailed={onFinishFailed}
		  autoComplete="off"
		>
		  <Form.Item
			label="E-Mail"
			name="email"
			rules={[{ required: true, message: "Please input your email!" }]}
		  >
			<Input type="email" />
		  </Form.Item>

		  <Form.Item
			label="Repeat E-Mail"
			name="emailValidation"
			rules={[{ required: true, message: "Please input your email!" }]}
		  >
			<Input type="email" />
		  </Form.Item>
  
		  <Form.Item
			label="Password"
			name="password"
			rules={[{ required: true, message: "Please input your password!" }]}
		  >
			<Input.Password />
		  </Form.Item>
  
		  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
			<Button type="primary" htmlType="submit">
			  Submit
			</Button>
		  </Form.Item>
		</Form> :  <Alert
			message="Beta access only"
			description="TeamTribe will open registration soon"
			type="info"
		  />}
	  </Space>
	); 
	
}

function useQuery() {
	throw new Error("Function not implemented.");
}
