import { Alert, Button, Card, Space, Tag, Timeline } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useCommunityNavigate } from "../../hooks/useCommunityNavigate";
import DashboardLayout from "../../layouts/DashboardLayout";
import { RootState } from "../../reduxToolkit/store";
import { EventOverviewCard } from "./EventOverviewCard";
import { ScheduledMessages } from "./ScheduledMessages";

export const Home: React.FC = () => { 
  const navigate = useNavigate();  
  const membership = useSelector((state : RootState) => state.common.currentMembership);  

  return (
    <DashboardLayout>
      <Space direction="vertical" style={{ width: "100%" }}>
        {/* !membership?.slackTeamId && <Alert
          message="Slack Bot is not linked yet."
          type="info"
          action={
            <Button size="small" type="text" onClick={() => navigate('/account/integrations')}>
              Link now
            </Button>
          }
        /> */} 
        <EventOverviewCard />
        <ScheduledMessages />
      </Space>
    </DashboardLayout>
  );
};
